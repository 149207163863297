
export const lang = {
	lang: '中文',
	// 倒计时
	days: 'days',
	hours: 'hours',
	minutes: 'minutes',
	seconds: 'seconds',
	// 通知
}

