import config from "@/config"
import http from "@/utils/request"

export default {
  user: {
    set: {
      url: `${config.API_URL}/user`,
      name: "编辑一个顾客组账号",
      post: async function (data) {
        return await http.post(this.url, { method: 'set.user.item', auth: true, ...data });
      }
    },
    password: {
      url: `${config.API_URL}/user`,
      name: "编辑顾客组账号密码",
      post: async function (data) {
        return await http.post(this.url, { method: 'set.user.password', auth: true, ...data });
      }
    },
    item: {
      url: `${config.API_URL}/user`,
      name: "获取一个顾客组账号详情",
      get: async function () {
        return await http.post(this.url, { method: 'get.user.item', auth: true, });
      }
    },
    findpwd: {
      url: `${config.API_URL}/login`,
      name: "忘记密码",
      post: async function (data) {
        return await http.post(this.url, { method: 'find.user.password', auth: false, ...data });
      }
    },
    sendcode: {
      url: `${config.API_URL}/login`,
      name: "获取短信验证码",
      post: async function (data) {
        return await http.post(this.url, { method: 'send.message.code', auth: false, ...data });
      }
    },
    mobilelogin: {
      url: `${config.API_URL}/login`,
      name: "用户登录",
      post: async function (data) {
        return await http.post(this.url, { method: 'user.mobile.login', auth: false, ...data });
      }
    },
    openid: {
      url: `${config.API_URL}/login`,
      name: "用户登录",
      post: async function (data) {
        return await http.post(this.url, { method: 'get.wechat.qrcode', auth: false, ...data });
      }
    },
    getcode: {
      url: `${config.API_URL}/login`,
      name: "PC获取code后登录",
      post: async function (data) {
        return await http.post(this.url, { method: 'get.user.info.pc', auth: true, ...data });
      }
    },
    register: {
      url: `${config.API_URL}/login`,
      name: "用户注册",
      post: async function (data) {
        return await http.post(this.url, { method: 'register.user.item', auth: false, ...data });
      }
    },
    logout: {
      url: `${config.API_URL}/login`,
      name: "退出登录",
      post: async function () {
        return await http.post(this.url, { method: 'set.user.loginout', auth: true, });
      }
    }
  },
}
