
export const lang = {
	lang: 'English',
	// 倒计时
	days: '天',
	hours: '时',
	minutes: '分',
	seconds: '秒',
	// 通知
}

